import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { updateOrder, updatePurchaseOrder } from "../../utils/api";
import CustomModal, { ModalInput, ModalSelect } from "../Modal";
import CustomSelect from "../Select";

const EditSalesPurchase = ({
  data,
  reload,
  customers,
  products,
  type = "purchase",
}) => {
  const [edit, setEdit] = useState(false);
  const [data2, setData2] = useState({});

  useEffect(() => {
    setData2({
      ...data,
      productId: data?.product?._id,
    });
  }, [data, edit]);
  return (
    <>
      <Button onClick={() => setEdit(true)} variant="contained">
        Edit
      </Button>
      <CustomModal
        open={edit}
        setOpen={() => {
          setEdit(false);
          setData2({});
        }}
        onClick={async () => {
          if (data2?.type === "PurchaseOrder") {
            const [success, _] = await updatePurchaseOrder(data2?._id, {
              ...data2,
              quantity: parseInt(data2.quantity),
              rate: parseFloat(data2.rate),
              gst: parseInt(data2.gst),
            });
            if (success) {
              toast.success("Purchase Order Updated");
              reload();
              setEdit(false);
              setData2({});
            }
          } else {
            const [success, _] = await updateOrder(data2?._id, {
              ...data2,
              quantity: parseInt(data2.quantity),
              rate: parseFloat(data2.rate),
              gst: parseInt(data2.gst),
            });
            if (success) {
              toast.success("Order Updated");
              reload();

              setEdit(false);
              setData2({});
            }
          }
        }}
      >
        <div className="flex flex-col gap-3">
          <div className="flex justify-between gap-2">
            <div className="  border-black ">Customer</div>
            <CustomSelect
              width={"256px"}
              onChange={(e) => {
                setData2({
                  ...data2,
                  customerId: e.target.value,
                });
              }}
              options={
                customers
                  ?.filter((item) => item?.type === "Customer")
                  ?.map((customer) => ({
                    value: customer._id,
                    label: customer.userName,
                  })) ?? []
              }
              value={data2.customerId || data2?.customer?._id}
            />
          </div>
          {type === "sales" && (
            <div className="flex justify-between gap-2">
              <div className="  border-black ">Product</div>
              <CustomSelect
                width={"256px"}
                onChange={(e) => {
                  setData2({
                    ...data2,
                    productId: e.target.value,
                  });
                }}
                options={products
                  .filter((item) => item?.productType === "Sale")
                  .map((item) => ({
                    value: item?._id,
                    label: item?.productName,
                  }))}
                value={data2?.productId}
              />
            </div>
          )}
          <ModalSelect
            title={"GST"}
            value={data2.gst}
            options={["0", "5", "12", "18"]}
            onChange={(e) => {
              setData2({
                ...data2,
                gst: e.target.value,
              });
            }}
          />
          <ModalInput
            title={"Quantity"}
            value={data2.quantity}
            onChange={(e) => {
              setData2({
                ...data2,
                quantity: e.target.value,
              });
            }}
          />
          <ModalInput
            title={"Rate"}
            value={data2.rate}
            onChange={(e) => {
              setData2({
                ...data2,
                rate: e.target.value,
              });
            }}
          />

          <ModalInput
            title={"description"}
            value={data2.description}
            onChange={(e) => {
              setData2({
                ...data2,
                description: e.target.value,
              });
            }}
          />
          <ModalInput
            title={"date"}
            type="date"
            value={
              typeof data2?.date === "string" && data2?.date?.split("T")?.[0]
            }
            onChange={(e) => {
              setData2({
                ...data2,
                date: e.target.value,
              });
            }}
          />
        </div>
      </CustomModal>
    </>
  );
};

export default EditSalesPurchase;
