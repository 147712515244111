import {
  Document,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import { clientDatabyid, getAllCustomer } from "../../utils/api";
import { formatDate } from "../../utils/formatData";
import useStore from "../../utils/store";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 10,
  },
  row: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    borderLeft: "1px solid #333",
  },
  row2: {
    flexDirection: "row",
    backgroundColor: "white",
    borderLeft: "1px solid #333",
  },
  headingTab: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingGstTab: {
    flex: 0.4,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingRateTab: {
    flex: 0.6,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingDateTab: {
    flex: 0.8,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingDateTab4: {
    flex: 0.8,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingQntTab: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingCustomerTab: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTotalTab: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTab2: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingTabPUR: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#2dd4bf",
    fontWeight: "bold",
  },
  headingGstTab2: {
    flex: 0.4,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingGstTabpur: {
    flex: 0.4,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#2dd4bf",
    fontWeight: "bold",
  },
  headingRateTab2: {
    flex: 0.6,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingRateTabpur: {
    flex: 0.6,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#2dd4bf",
    fontWeight: "bold",
  },
  headingDateTab2: {
    flex: 0.8,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingDateTabpur: {
    flex: 0.8,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#2dd4bf",
    fontWeight: "bold",
  },
  headingQntTab2: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingQntTabpur: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#2dd4bf",
    fontWeight: "bold",
  },
  headingTotalTab2: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingTotalTabpur: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#2dd4bf",
    fontWeight: "bold",
  },
  headingTab3: {
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTabGreen: {
    flex: 1.4,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },
  headingTabDateGreen: {
    flex: 1.15,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },
  headingTabGreen2: {
    flex: 4.53,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },
  headingTotalTabGreen: {
    flex: 0.99,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },

  headingTab4: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "center",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingGstTab4: {
    flex: 0.4,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingRateTab4: {
    flex: 0.6,
    fontSize: 7.4,
    textAlign: "center",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingQntTab4: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderLeft: "1px solid #333",
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingCustomerTab4: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTotalTab4: {
    flex: 0.7,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  section: {
    margin: 10,
    marginRight: 10,
    border: "1px solid #333",
    width: "100%",
    flexDirection: "row",
  },
  text: {
    flex: 1,
  },
});

const MyDocument = ({ list, total, type }) => {
  const res = type === "Customer" ? list : list;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            borderBottom: "1px solid #333",
          }}
        >
          <View style={styles.row}>
            <Text style={styles.headingDateTab}>Date</Text>
            <Text style={styles.headingTab}>Customer</Text>
            <Text style={styles.headingTab}>Location</Text>
            <Text style={styles.headingTab}>description</Text>
            <Text style={styles.headingTab}>Items</Text>
            <Text style={styles.headingQntTab}>Qnt.</Text>
            <Text style={styles.headingRateTab}>Rate</Text>
            <Text style={styles.headingGstTab}>Gst</Text>
            <Text style={styles.headingTotalTab}>Total</Text>
          </View>
          {res?.map((record, index) => (
            <View key={index}>
              {record?.type === "PurchaseOrder" ? (
                <View style={styles.row}>
                  <Text style={styles.headingDateTabpur}>
                    {formatDate(record?.date)}
                  </Text>
                  <Text style={styles.headingTabPUR}>
                    {record?.customer?.userName}
                  </Text>
                  <Text style={styles.headingTabPUR}>
                    {record?.customer?.group?.name}
                  </Text>
                  <Text style={styles.headingTabPUR}>
                    {record?.description}
                  </Text>
                  <Text style={styles.headingTabPUR}>
                    {record?.product?.productName}
                  </Text>
                  <Text style={styles.headingQntTabpur}>
                    {record?.quantity}
                  </Text>
                  <Text style={styles.headingRateTabpur}>{record?.rate}</Text>
                  <Text style={styles.headingGstTabpur}>{record?.gst}</Text>
                  <Text style={styles.headingTotalTabpur}>
                    {record.totalAmount?.toFixed(2)}
                  </Text>
                </View>
              ) : record?.type === "Order" ? (
                <View style={styles.row}>
                  <Text style={styles.headingDateTab2}>
                    {formatDate(record?.completedDate)}
                  </Text>
                  <Text style={styles.headingTab2}>
                    {record?.customer?.userName}
                  </Text>
                  <Text style={styles.headingTab2}>
                    {record?.customer?.group?.name}
                  </Text>
                  <Text style={styles.headingTab2}>
                    {record?.description}{" "}
                    {record?.dispatchNote ? `, ${record?.dispatchNote}` : ""}
                  </Text>
                  <Text style={styles.headingTab2}>
                    {record?.product?.productName}
                  </Text>
                  <Text style={styles.headingQntTab2}>{record?.quantity}</Text>
                  <Text style={styles.headingRateTab2}>{record?.rate}</Text>
                  <Text style={styles.headingGstTab2}>{record?.gst}</Text>
                  <Text style={styles.headingTotalTab2}>
                    {record?.totalAmount?.toFixed(2)}
                  </Text>
                </View>
              ) : (
                <View style={styles.row}>
                  <Text style={styles.headingTabDateGreen}>
                    {formatDate(record?.date)}
                  </Text>
                  <Text style={styles.headingTabGreen}>
                    {" "}
                    {record?.customer?.userName}
                  </Text>
                  <Text style={styles.headingTabGreen}>
                    {record?.customer?.group?.name}
                  </Text>

                  <Text style={styles.headingTabGreen}>
                    {" "}
                    {record?.description}
                  </Text>

                  <Text style={styles.headingTabGreen2}>
                    {" "}
                    {record?.paymentType}
                  </Text>
                  <Text style={styles.headingTotalTabGreen}>
                    {" "}
                    {record?.amount?.toFixed(2)}
                  </Text>
                </View>
              )}
            </View>
          ))}
          <View style={styles.row}>
            <Text style={styles.headingDateTab4}></Text>
            <Text style={styles.headingTab4}></Text>
            <Text style={styles.headingTab4}>Total</Text>
            <Text style={styles.headingTab4}></Text>
            <Text style={styles.headingTab4}></Text>
            <Text style={styles.headingQntTab4}>
              {list
                .filter((c) => c.quantity !== undefined)
                .reduce((a, b) => a + b.quantity, 0)
                ?.toFixed(0)}
            </Text>
            <Text style={styles.headingRateTab4}></Text>
            <Text style={styles.headingGstTab4}></Text>
            <Text style={styles.headingTotalTab4}>{total.toFixed(2)}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const Pdfconverter = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const params = useParams();
  const user = useStore((state) => state.user);
  console.log(user);
  const getdata = async () => {
    if (params.id === "all") {
      const data = await getAllCustomer(user.token);
      if (data[0]) {
        setList(data[1].records);
        setTotal(data[1].totalBalance);
      } else {
        toast.error("Something went wrong");
      }
      setIsLoading(false);
    } else {
      const data = await clientDatabyid(params.id, startDate, endDate);
      if (data[0]) {
        setList(data[1].records);
        setTotal(data[1].totalBalance);
      } else {
        toast.error("Something went wrong");
      }
      setIsLoading(false);
    }
  };
  const saveFile = () => {
    // This does the trick!
    pdf(<MyDocument list={list} total={total} type={user?.type} />)
      .toBlob()
      .then((blob) =>
        saveAs(
          blob,
          `${params.id === "all" ? "All" : list[0]?.customer?.userName}.pdf`
        )
      );
  };
  useEffect(() => {
    getdata();
  }, [params.id]);

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <button
        onClick={saveFile}
        className="bg-primary w-full py-2 text-center text-white font-medium text-sm h-10"
      >
        Save File
      </button>
      <PDFViewer
        className="
  "
        style={{ width: "100%", height: "calc(100vh - 40px)" }}
      >
        <MyDocument list={list} total={total} type={user?.type} />
      </PDFViewer>{" "}
    </div>
  );
};

export default Pdfconverter;
