import {
  Document,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import {
  getAllTagOrders,
  getMarketDashboardHandler,
  getStancilStaff,
  getTradingStaffOrder,
} from "../../utils/api";
import { formatDescription } from "../../utils/formatData";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 25,
  },
  row: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    borderLeft: "1px solid #333",
  },
  row2: {
    flexDirection: "row",
    backgroundColor: "white",
    borderLeft: "1px solid #333",
  },
  headingTab: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingGstTab: {
    flex: 0.4,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingRateTab: {
    flex: 0.6,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingDateTab: {
    flex: 0.8,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingQntTab: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingCustomerTab: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTotalTab: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTab2: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingGstTab2: {
    flex: 0.4,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingRateTab2: {
    flex: 0.6,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingDateTab2: {
    flex: 0.8,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingQntTab2: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingTotalTab2: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "white",
    fontWeight: "bold",
  },
  headingTab3: {
    fontSize: 7.4,
    textAlign: "end",
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTabGreen: {
    flex: 1.4,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },
  headingTabDateGreen: {
    flex: 1.15,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },
  headingTabGreen2: {
    flex: 4.53,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },
  headingTotalTabGreen: {
    flex: 0.99,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: 5,
    backgroundColor: "#1EB14B",
    fontWeight: "bold",
  },
  headingDateTab4: {
    flex: 0.8,
    fontSize: 7.4,
    textAlign: "end",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTab4: {
    flex: 1,
    fontSize: 7.4,
    textAlign: "center",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingGstTab4: {
    flex: 0.4,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingRateTab4: {
    flex: 0.6,
    fontSize: 7.4,
    textAlign: "center",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingQntTab4: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderLeft: "1px solid #333",
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingCustomerTab4: {
    flex: 0.7,
    fontSize: 7.4,
    textAlign: "center",
    borderRight: "1px solid #333",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  headingTotalTab4: {
    flex: 0.7,
    fontSize: 7.4,
    borderRight: "1px solid #333",
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: "#fff101",
    fontWeight: "bold",
  },
  section: {
    margin: 10,
    marginRight: 10,
    border: "1px solid #333",
    width: "100%",
    flexDirection: "row",
  },
  text: {
    flex: 1,
  },
});

const PdfconverterStaff = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();

  const getdata = async () => {
    const [sucesss, data] =
      params?.id === "market"
        ? await getMarketDashboardHandler()
        : params?.id === "tag"
        ? await getAllTagOrders()
        : params?.id === "stancil"
        ? await getStancilStaff()
        : await getTradingStaffOrder();
    if (sucesss) {
      setList(data);
    } else {
      toast.error("Something went wrong");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getdata();
  }, [params.id]);

  return isLoading ? (
    <Loading />
  ) : (
    <PDFViewer
      className="
    "
      style={{ width: "100%", height: "100vh" }}
    >
      <Document>
        <Page size="A4" style={styles.page}>
          <View>
            <View style={styles.row}>
              <Text style={styles.headingDateTab}>Date</Text>
              <Text style={styles.headingTab}>Customer</Text>
              <Text style={styles.headingTab}>Location</Text>
              <Text style={styles.headingTab}>description</Text>
              <Text style={styles.headingTab}>Items</Text>
              <Text style={styles.headingQntTab}>Qnt.</Text>
              {params?.id === "market" && (
                <>
                  <Text style={styles.headingRateTab}>Rate</Text>
                  <Text style={styles.headingGstTab}>Gst</Text>
                  <Text style={styles.headingTotalTab}>Total</Text>
                </>
              )}
            </View>
            {list?.map((record, index) => (
              <View key={index}>
                {record.type === "Order" ? (
                  <View style={styles.row}>
                    <Text style={styles.headingDateTab2}>
                      {new Date(record?.updatedAt).getUTCDate()}-
                      {new Date(record?.updatedAt).getUTCMonth() + 1}-
                      {new Date(record?.updatedAt).getUTCFullYear()}
                    </Text>
                    <Text style={styles.headingTab2}>
                      {record?.customer?.userName}
                    </Text>
                    <Text style={styles.headingTab2}>
                      {record?.customer?.group?.name}
                    </Text>
                    <Text style={styles.headingTab2}>
                      {formatDescription(
                        record?.description,
                        record?.dispatchNote
                      )}
                    </Text>
                    <Text style={styles.headingTab2}>
                      {record?.product?.productName}
                    </Text>
                    <Text style={styles.headingQntTab2}>
                      {record?.quantity}
                    </Text>
                    {params?.id === "market" && (
                      <>
                        <Text style={styles?.headingRateTab2}>
                          {record.rate}
                        </Text>
                        <Text style={styles.headingGstTab2}>{record?.gst}</Text>
                        <Text style={styles.headingTotalTab2}>
                          {record?.totalAmount?.toFixed(2)}
                        </Text>
                      </>
                    )}
                  </View>
                ) : (
                  <View style={styles.row}>
                    <Text style={styles.headingTabDateGreen}>
                      {new Date(record?.createdAt).getUTCDate()}-
                      {new Date(record?.createdAt).getUTCMonth() + 1}-
                      {new Date(record?.createdAt).getUTCFullYear()}
                    </Text>
                    <Text style={styles.headingTabGreen}>
                      {record?.customer?.group.name}
                    </Text>

                    <Text style={styles.headingTabGreen}>
                      {" "}
                      {record?.description}
                    </Text>

                    <Text style={styles.headingTabGreen2}>
                      {" "}
                      {record?.paymentType}
                    </Text>
                    <Text style={styles.headingTotalTabGreen}>
                      {" "}
                      {record?.amount?.toFixed(2)}
                    </Text>
                  </View>
                )}
              </View>
            ))}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PdfconverterStaff;
