import React, { useState } from "react";

import {
  clientDatabyid,
  getAdminCustomerAccess,
  getAllInvoiceNo,
  getInvoiceNo,
  getSubCustomerById,
  submitInvoiceDetail,
} from "../utils/api";

import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useStore from "../utils/store";
import ClientTable from "./ClientTable";
import Dash from "./Dash";
import Loading from "./Loading";
import CustomModal, {
  ModalDatePicker,
  ModalInput,
  ModalSelect2,
  ModalSelectAutoComplete,
} from "./Modal";
import InvoicesModal from "./modals/InvoicesModal";
import Search from "./Search";

const ClientSearch = ({ id, customers, client }) => {
  const [access, setAccess] = useState([]);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const [loading, setLoading] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [data, setData] = useState(null);
  const [createInvoice, setCreateInvoice] = useState(null);
  const [invoiceNo, setInvoiceNo] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [orderIds, setOrderIds] = useState(null);
  const [isPartialInvoice, setIsPartialInvoice] = useState(true);
  const [partialQuantity, setPartialQuantity] = useState("");
  const [productList, setProductList] = useState(null);
  const [invoiceCustomers, setCustomers] = useState([]);
  console.log(id);
  var label;

  const getAllCustomerHandler = async () => {
    setLoading(true);
    const [success, data] = await getAdminCustomerAccess(
      id,
      startDate,
      endDate
    );
    if (success) {
      setAccess(
        data.map((item) => ({
          ...item,
          name: item?.userName,
        }))
      );
    }
    setLoading(false);
  };
  const getAllCustomersData = async () => {
    setLoading(true);
    const [success, data] = await getSubCustomerById(id, startDate, endDate);
    if (success) {
      setData({
        name: "ALL",
        cur: `${id}ALL`,
        ...data,
      });
    }
    setLoading(false);
  };

  const createInvoicePayload = () => ({
    ...invoiceData,
    invoiceNumberString: invoiceNo,
    customerId: data?.name === "ALL" ? invoiceData.customerId : data.cur,
    orderIds: orderIds?.split(","),
    ...(isPartialInvoice && { quantity: partialQuantity }),
  });

  const resetInvoiceState = () => {
    setCreateInvoice(false);
    setOrderIds(null);
    setPartialQuantity("");
    setInvoiceData(null);
    setInvoiceNo("");
    setInvoiceDate(null);
  };

  const handleInvoiceSubmit = async (isPrint = false) => {
    if (isPartialInvoice && !partialQuantity) {
      toast.error("Please enter quantity for partial invoice");
      return;
    }

    if (!orderIds) {
      toast.error("Please select a product");
      return;
    }

    setLoading(true);
    try {
      const endpoint = "/admin/invoice-partial";
      const payload = createInvoicePayload();

      const [success, res] = await submitInvoiceDetail(payload, endpoint);

      if (success) {
        resetInvoiceState();
        toast.success("Invoice Created successfully");

        // Only route to invoice page for full invoices and print action
        if (isPrint && !isPartialInvoice) {
          router(`/admin/invoice/${res._id}`);
        }
      }
    } catch (error) {
      toast.error("Failed to create invoice");
    } finally {
      setLoading(false);
    }
  };

  const invoiceHandler = () => handleInvoiceSubmit(false);
  const printinvoiceHandler = () => handleInvoiceSubmit(true);

  const invoiceNoHandler = async (customerId) => {
    setInvoiceLoading(true);

    const [success, res] =
      data?.name === "ALL"
        ? await getAllInvoiceNo({
            clientId: data?.cur?.split("ALL")?.[0],
          })
        : await getInvoiceNo({
            customerId: data?.cur,
          });
    if (success) {
      setInvoiceNo(res.invoiceNumberString);
      setProductList(res.products);
      if (data?.name === "ALL") setCustomers(res?.customers);
    }
    setInvoiceLoading(false);
  };
  const getCustomersByIdHandler = async (i, name) => {
    setLoading(true);
    const [success, data] = await clientDatabyid(i, startDate, endDate);
    if (success) {
      setData({
        ...data,
        name,
        cur: i,
        active: name,
      });
    }
    setLoading(false);
  };

  const router = useNavigate();

  React.useEffect(() => {
    getAllCustomerHandler();
    getAllCustomersData();
  }, [id, startDate, endDate]);

  return (
    <div className="flex gap-6">
      {loading && <Loading />}
      <Search
        data={[
          {
            name: "ALL",
            _id: `${id}ALL`,
          },
          ...access,
        ]}
        active={data?.cur}
        item={(item) => {
          if (item.name === "ALL") {
            getAllCustomersData();

            return;
          }
          getCustomersByIdHandler(item._id, item?.name);
        }}
      />
      {data && (
        <div className="flex flex-col gap-6 flex-1 md:w-[calc(100%-15rem)]">
          <div className="flex gap-6 flex-wrap ">
            <div className="flex  text-red-500 w-fit font-semibold  bg-yellow-400 px-3 py-2 border border-black">
              {data?.name}
            </div>
            <div className="flex  text-black w-fit font-semibold  bg-secondary px-3 py-2 border border-black">
              Wagon : {data?.name === "ALL" ? data?.wagons : data?.wagon}
            </div>
            <div className="flex  text-black w-fit font-semibold  bg-secondary px-3 py-2 border border-black">
              Tag :
              {data?.name === "ALL" ? data?.wagons * 580 : data?.wagon * 580}
            </div>
            <div className="flex  text-black w-fit font-semibold  bg-secondary px-3 py-2 border border-black">
              Phone Number :
              {access.find((item) => item._id === data?.cur)?.phoneNumber}
            </div>
            <div className="flex  text-black w-fit font-semibold  bg-secondary px-3 py-2 border border-black">
              Completed Tag :{data?.orderTagAmount}
            </div>
            <div className="flex  text-black w-fit font-semibold  bg-secondary px-3 py-2 border border-black">
              Balance :
              {(data?.name !== "ALL" && data?.wagon !== 0) ||
              (data?.name === "ALL" && data?.wagons !== 0)
                ? parseInt(
                    data?.name === "ALL"
                      ? access.reduce((a, b) => a + b?.wagon, 0) * 580
                      : access.find((item) => item._id === data?.cur)?.wagon *
                          580
                  ) - parseInt(data?.orderTagAmount)
                : 0}
            </div>
            <InvoicesModal
              id={data?.name === "ALL" ? id : data?.cur}
              client={data?.name === "ALL"}
            />
            <button
              className="flex  text-white w-fit font-semibold  bg-primary px-3 py-2 border border-black cursor-pointer"
              onClick={() => {
                invoiceNoHandler(data?.cur);
                setCreateInvoice(true);
              }}
            >
              Create Bill
            </button>
            <Link
              className="flex  text-white w-fit font-semibold  bg-primary px-3 py-2 border border-black cursor-pointer"
              to={
                data?.name === "ALL"
                  ? `/pdf/ledger/all/${id}`
                  : `/pdf/ledger/customer/${data?.cur}`
              }
            >
              Ledger
            </Link>
          </div>
          <div className="flex gap-6">
            <Dash title={"Sale"} value={data?.totalSales?.toFixed(2)} />
            <Dash
              title={"Payment Recived"}
              value={data?.totalPaymentRecived?.toFixed(2)}
            />
            <Dash title={"Balance"} value={data?.totalBalance?.toFixed(2)} />
          </div>
          <ClientTable
            reload={
              data?.name === "ALL"
                ? getAllCustomersData
                : () => getCustomersByIdHandler(data?.cur, data?.name)
            }
            id={data?.cur}
            allid={id}
            all={data?.name === "ALL"}
            data={[...data?.records]?.reverse()}
            customers={customers}
            client
            columns={[
              {
                title: "Date",
                dataIndex: "date",
              },
              {
                title: "Customer",
              },
              {
                title: "description",
                dataIndex: "description",
                render: (item) => {
                  return `${item} ${
                    item.dispatchNote ? `,${item.dispatchNote}` : ""
                  }`;
                },
              },
              {
                title: "Items",
              },
              {
                title: "Qnt.",
                dataIndex: "qnt",
              },
              {
                title: "Rate",
                dataIndex: "rate",
              },
              {
                title: "GST",
                dataIndex: "gst",
              },
              {
                title: "Total",
              },
              {
                title: "User",
              },
              {
                title: "Image",
              },
            ]}
          />
        </div>
      )}

      <CustomModal
        open={createInvoice}
        setOpen={() => {
          setCreateInvoice(false);
          setPartialQuantity("");
        }}
        button={
          <div className="flex gap-6">
            <Button
              variant="contained"
              color="primary"
              onClick={printinvoiceHandler}
            >
              {invoiceLoading ? "Loading..." : "Save and Print"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={invoiceHandler}
            >
              {invoiceLoading ? "Loading..." : "Save"}
            </Button>
          </div>
        }
        loading={invoiceLoading}
      >
        <ModalInput
          title="Invoice No"
          onChange={(e) => setInvoiceNo(e.target.value)}
          value={invoiceNo}
        />
        <ModalDatePicker
          title="Invoice Date"
          onChange={(e) => {
            setInvoiceDate(e.target.value);
            setInvoiceData({
              ...invoiceData,
              invoiceDate: e.target.value + "T13:40:31.520Z",
            });
          }}
          value={invoiceDate}
        />
        <ModalInput
          title="Note"
          onChange={(e) => {
            setInvoiceData({
              ...invoiceData,
              note: e.target.value,
            });
          }}
          value={invoiceData?.note || ""}
        />
        <ModalSelect2
          title="Product"
          value={label}
          options={productList}
          onChange={(e) => {
            let index = e.nativeEvent.target.selectedIndex;
            label = e.nativeEvent.target[index].text;
            setOrderIds(e.target.value);
          }}
        />
        {isPartialInvoice && (
          <ModalInput
            title="Quantity"
            type="number"
            onChange={(e) => setPartialQuantity(e.target.value)}
            value={partialQuantity}
          />
        )}
        {data?.name === "ALL" && invoiceCustomers && (
          <ModalSelectAutoComplete
            title="Customer"
            value={label}
            options={
              invoiceCustomers?.map((item) => ({
                value: item._id,
                label: item.userName,
              })) || []
            }
            onChange={(e) => {
              setInvoiceData({
                ...invoiceData,
                customerId: e,
              });
            }}
          />
        )}
      </CustomModal>
    </div>
  );
};

export default ClientSearch;
