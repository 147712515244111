import React from "react";
import { toast } from "react-toastify";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import DispatchTable from "../../components/dispatchTable";
import { dispatchComplete, getAllDispatch } from "../../utils/api";
import useStore from "../../utils/store";

const DispatchByAdmin = () => {
  const [dash, setDash] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const [dispatchNote, setDispatchNote] = React.useState("");
  const getData = async () => {
    setLoading(true);
    const [success, data] = await getAllDispatch(startDate, endDate);
    if (success) {
      setDash(data);
    }
    setLoading(false);
  };
  const user = useStore((state) => state.user);
  React.useEffect(() => {
    getData();
  }, [startDate, endDate]);
  return (
    <Layout>
      {loading && <Loading />}
      <DispatchTable
        type="dispatch"
        data={dash}
        complete={async (ids) => {
          setLoading(true);
          const [success, data] = await dispatchComplete({
            type: "admin",
            completedById: user.admin._id,
            ids: ids,
            dispatchNote,
          });
          if (success) {
            toast.success("Dispatch Completed");
            getData();
            setDispatchNote("");
          } else {
            toast.error(data);
          }
          setLoading(false);
        }}
        dispatchNote={dispatchNote}
        setDispatchNote={setDispatchNote}
      />
    </Layout>
  );
};

export default DispatchByAdmin;
