import {
  Document,
  Font,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WorkBold from "../../assets/fonts/WorkSans-Bold.ttf";
import WorkBoldItalic from "../../assets/fonts/WorkSans-BoldItalic.ttf";
import WorkRegular from "../../assets/fonts/WorkSans-Regular.ttf";
import WorkSemiBold from "../../assets/fonts/WorkSans-SemiBold.ttf";
import Loading from "../../components/Loading";
import { getLedgerByAll, getLedgerById } from "../../utils/api";
import { formatDate } from "../../utils/formatData";

Font.register({
  family: "WorkSans",
  src: WorkRegular,
  fonts: [
    { src: WorkRegular },
    { src: WorkBold, fontWeight: "bold" },
    { src: WorkBoldItalic, fontWeight: "bold", fontStyle: "italic" },
    { src: WorkSemiBold, fontWeight: "semibold" },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 25,
  },
  headText: {
    fontFamily: "WorkSans",
    fontSize: 12,
    textAlign: "center",
  },
  col1: {
    width: 60,
    textAlign: "left",
    fontSize: 10,
  },
  col2: {
    width: 20,
    textAlign: "left",
    fontSize: 10,
  },
  col3: { flex: 0.7, textAlign: "left", fontSize: 10 },
  col4: { flex: 1.2, textAlign: "left", fontSize: 10 },
  col5: { flex: 1, textAlign: "left", fontSize: 10 },
  col6: { flex: 0.6, textAlign: "left", fontSize: 10 },
  col7: { flex: 0.6, textAlign: "left", fontSize: 10 },
  col8: { flex: 0.8, textAlign: "right", fontSize: 10 },
});

const calculateBalance = (records, upToIndex = null) => {
  const recordsToCalculate =
    upToIndex !== null ? records?.slice(0, upToIndex + 1) : records;

  return (
    recordsToCalculate?.reduce((acc, curr) => {
      switch (curr?.type) {
        case "Invoice":
          return acc - curr?.grandTotal;
        case "Payment":
          return curr?.paymentType === "Received"
            ? acc + curr?.amount
            : acc - curr?.amount;
        case "PurchaseOrder":
          return acc + curr?.totalAmount;
        default:
          return acc;
      }
    }, 0) || 0
  );
};

const isDebitTransaction = (item) => {
  return (
    item?.type === "Invoice" ||
    (item?.type === "Payment" && item?.paymentType === "Payment")
  );
};

const isCreditTransaction = (item) => {
  return (
    (item?.type === "Payment" && item?.paymentType === "Received") ||
    item?.type === "PurchaseOrder"
  );
};

const Ledger = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();

  const fetchLedgerData = async () => {
    try {
      setIsLoading(true);

      const [success, data] = await (params?.type === "all"
        ? getLedgerByAll(params?.id)
        : getLedgerById(params?.id));

      if (success) {
        setList(data);
      }
    } catch (error) {
      console.error("Error fetching ledger data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveFile = () => {
    pdf(<MyDocument list={list} />)
      .toBlob()
      .then((blob) =>
        saveAs(
          blob,
          `${
            params.type === "all"
              ? list?.account?.userName
              : list?.customer?.userName
          }-ledger.pdf`
        )
      );
  };

  useEffect(() => {
    fetchLedgerData();
  }, [params.id, params?.type]);

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <button
        onClick={saveFile}
        className="bg-primary w-full py-2 text-center text-white font-medium text-sm h-10"
      >
        Save File
      </button>
      <PDFViewer
        className=""
        style={{ width: "100%", height: "calc(100vh - 40px)" }}
      >
        <MyDocument list={list} />
      </PDFViewer>
    </div>
  );
};

export default Ledger;

const MyDocument = ({ list }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Text
            style={{
              fontFamily: "WorkSans",
              fontSize: 18,
              textAlign: "center",
            }}
          >
            PRABHU LABELS
          </Text>
          <Text style={styles.headText}>
            D-298 Shaheed Bhagat Singh Nagar, Dhandra Road, Ludhiana
          </Text>
          <Text style={styles.headText}>GSTIN : 03ABLPY4996M1ZM</Text>
          <Text style={{ ...styles.headText, letterSpacing: 2 }}>LEDGER</Text>
          <Text style={styles.headText}>
            (From {formatDate(list?.systemValues?.startDate)} to{" "}
            {formatDate(list?.systemValues?.endDate)})
          </Text>
          <Text style={styles.headText}>
            Account : {list?.customer?.userName ?? list?.account?.userName}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            marginTop: 10,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              paddingVertical: 5,
              gap: 10,
            }}
          >
            <Text style={styles.col1}>Date</Text>
            <Text style={styles.col2}>Type</Text>
            <Text style={styles.col3}>Vch No.</Text>
            <Text style={styles.col4}>Particulars</Text>
            <Text style={styles.col5}>Narration</Text>
            <Text style={styles.col6}>Debit (`)</Text>
            <Text style={styles.col7}>Credit (`)</Text>
            <Text style={styles.col8}>Balance (`)</Text>
          </View>
          <View style={{ display: "flex", flexDirection: "column", gap: 4 }}>
            {list?.records?.map((item, index) => (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                }}
                key={index}
              >
                <Text style={styles.col1}>
                  {formatDate(item?.date ?? item?.invoiceDate)}
                </Text>
                <Text style={styles.col2}>
                  {item?.type === "Invoice" ? "Sale" : "Rcpt"}
                </Text>
                <Text style={styles.col3}>{item?.invoiceNumberString}</Text>
                <Text style={styles.col4}>
                  {item?.type == "Payment" ? item.paymentType : item?.type}
                </Text>
                <Text style={styles.col5}>{item?.description}</Text>
                <Text style={styles.col6}>
                  {isDebitTransaction(item) &&
                    (item?.type === "Invoice"
                      ? item?.grandTotal?.toFixed(2)
                      : item?.amount?.toFixed(2))}
                </Text>
                <Text style={styles.col7}>
                  {isCreditTransaction(item) &&
                    (item?.type === "PurchaseOrder"
                      ? item?.totalAmount?.toFixed(2)
                      : item?.amount?.toFixed(2))}
                </Text>
                <Text style={styles.col8}>
                  {Math.abs(calculateBalance(list?.records, index))?.toFixed(2)}{" "}
                  {calculateBalance(list?.records, index) < 0 ? "Dr" : "Cr"}
                </Text>
              </View>
            ))}
            <View
              style={{
                borderTop: "1px solid black",
                marginTop: 5,
                display: "flex",
                flexDirection: "row",
                fontSize: 10,
                justifyContent: "flex-end",
                gap: 10,
                flex: 1,
              }}
            >
              <Text style={{ paddingVertical: 5 }}>Balance:</Text>
              <Text style={{ paddingVertical: 5 }}>
                {Math.abs(calculateBalance(list?.records))?.toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
