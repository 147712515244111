import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout3 from "../../../components/Layout/Layout3";
import Loading from "../../../components/Loading";
import { ModalInput, ModalSelect2 } from "../../../components/Modal";
import CustomSelect from "../../../components/Select";
import {
  getAllCustomers,
  getInvoiceNo,
  submitInvoiceDetail,
} from "../../../utils/api";
import useStore from "../../../utils/store";

const MarkInvoices = () => {
  const [loading, setLoading] = React.useState(false);
  const [newPayment, setNewPayment] = React.useState({});
  const user = useStore((state) => state.user);
  const [customers, setCustomers] = React.useState([]);
  var label;
  const [orderIds, setOrderIds] = React.useState(null);
  const [invoiceLoading, setInvoiceLoading] = React.useState(false);

  const [customerId, setCustomerId] = React.useState("");
  const [invoiceNo, setInvoiceNo] = React.useState("");
  const [productList, setProductList] = React.useState([]);
  const [invoiceDate, setInvoiceDate] = React.useState(null);
  const [invoiceData, setInvoiceData] = React.useState(null);
  const router = useNavigate();
  const getAllCustomerHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomers();
    if (success) {
      setCustomers(
        data.map((item) => ({
          ...item,
          name: `${item.userName} ${
            item?.group?.name !== undefined ? `(${item?.group?.name})` : ""
          }`,
        }))
      );
    }
    setLoading(false);
  };
  const invoiceNoHandler = async (customerId) => {
    setInvoiceLoading(true);
    const [success, data] = await getInvoiceNo({
      customerId: customerId,
    });
    if (success) {
      setInvoiceNo(data.invoiceNumberString);
      setProductList(data.products);
    }
    setInvoiceLoading(false);
  };

  const invoiceHandler = async () => {
    setLoading(true);
    const [success, data] = await submitInvoiceDetail({
      ...invoiceData,
      invoiceNumberString: invoiceNo,
      customerId: customerId,
      orderIds: orderIds?.split(","),
    });

    if (success) {
      toast.success("Invoice Created successfully");

      setOrderIds(null);
    }
    setLoading(false);
  };
  const printinvoiceHandler = async () => {
    setLoading(true);
    const [success, data] = await submitInvoiceDetail({
      ...invoiceData,
      invoiceNumberString: invoiceNo,
      customerId: customerId,
      orderIds: orderIds?.split(","),
    });
    console.log(data);
    if (success) {
      console.log(data);

      toast.success("Invoice Created successfully");
      //blank page
      router(`/admin/invoice/${data._id}`);

      setOrderIds(null);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getAllCustomerHandler();
  }, []);

  return (
    <Layout3 title={user?.staff?.userName}>
      {loading && <Loading />}

      <div className="flex md:items-center justify-center gap-3 md:flex-row flex-col bg-white py-10 px-10">
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Customer
          </div>
          <CustomSelect
            onChange={(e) => {
              setCustomerId(e.target.value);
              invoiceNoHandler(e.target.value);
            }}
            options={customers
              .filter((item) => item?.type === "Customer")
              .map((customer) => ({
                value: customer._id,
                label: `${customer.userName} (${customer?.group?.name})`,
              }))}
            value={customerId}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 whitespace-nowrap border-black px-2 py-1">
            Invoice Number
          </div>
          <ModalInput width={"w-full"} value={invoiceNo} disabled />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Date Pick
          </div>
          <ModalInput
            width={"w-full"}
            onChange={(e) => {
              setInvoiceDate(e.target.value);
              var result = e.target.value + "T13:40:31.520Z";
              setInvoiceData({
                ...invoiceData,
                invoiceDate: result,
              });
            }}
            value={invoiceDate}
            type="date"
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 border-black px-2 py-1">
            Products
          </div>
          <ModalSelect2
            title={""}
            value={label}
            options={productList}
            onChange={(e) => {
              let index = e.nativeEvent.target.selectedIndex;
              label = e.nativeEvent.target[index].text;
              setOrderIds(e.target.value);
              // orderIds?.split(",").join(`","`);
            }}
          />
        </div>

        <div className="flex flex-col gap-2">
          <div className="bg-secondary border-2 whitespace-nowrap border-black px-2 py-1">
            Note
          </div>
          <ModalInput
            width={"w-full"}
            onChange={(e) => {
              setInvoiceData({ ...invoiceData, note: e.target.value });
            }}
            value={invoiceData?.note || ""}
          />
        </div>

        <div className="flex flex-col gap-6">
          <Button
            variant="contained"
            color="primary"
            onClick={printinvoiceHandler}
          >
            {invoiceLoading ? "Loading..." : "Save and Print"}
          </Button>
          {/* <Button variant="contained" color="primary" onClick={invoiceHandler}>
            {invoiceLoading ? "Loading..." : "Save"}
          </Button> */}
        </div>
      </div>
    </Layout3>
  );
};

export default MarkInvoices;
