import React from "react";
import { Link } from "react-router-dom";
import CustomModal from "./Modal";

const DispatchTableCompleted = ({ data, complete }) => {
  return (
    <div className="flex flex-col gap-6">
      {data?.map((item, index) => {
        return (
          <div className="flex flex-col gap-6" key={index}>
            <div className="flex gap-6 items-center justify-center">
              <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
                Date: {item._id}
              </th>
              {item.tag > 0 && (
                <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
                  Tag: {item.tag}
                </th>
              )}
              {item.stancil > 0 && (
                <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
                  Stancil: {item.stancil}
                </th>
              )}
              {item.trading > 0 && (
                <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
                  Trading: {item.trading}
                </th>
              )}
            </div>

            <div className="w-full overflow-x-auto ">
              <table className="table-auto border-separate border-spacing-4">
                <thead>
                  <tr>
                    <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
                      Date
                    </th>
                    <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
                      City
                    </th>
                    <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
                      Location
                    </th>
                    <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
                      Items
                    </th>
                    <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
                      Quantity
                    </th>
                    <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
                      PhoneNumber
                    </th>{" "}
                    <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
                      Customer Name
                    </th>{" "}
                    {/* <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
                      Description
                    </th>{" "} */}
                    <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
                      Image
                    </th>{" "}
                    <th className="border whitespace-nowrap border-black px-2 py-1 bg-red-600">
                      <Link
                        to={`/pdf/admin/complete`}
                        target="_blank"
                        className="text-white"
                      >
                        PDF
                      </Link>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {item?.data?.map((date, index2) => {
                    const orders = date?.orders?.length;
                    return (
                      <React.Fragment key={index2}>
                        {date?.orders?.map((order, index3) => {
                          return (
                            <tr key={index3}>
                              <td className="border border-black px-2 py-1">
                                {/* 14:99 Am */}
                                {new Date(order?.updatedAt).toLocaleTimeString(
                                  "en-US",
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )}
                              </td>
                              <td className="border border-black px-2 py-1">
                                {order?.group}
                              </td>
                              <td className="border border-black px-2 py-1">
                                {order?.customer?.location}
                              </td>
                              <td className="border border-black px-2 py-1">
                                {order?.product?.productName}
                              </td>
                              <td className="border border-black px-2 py-1">
                                {order?.quantity}
                              </td>
                              {index3 === 0 && (
                                <td
                                  rowSpan={orders}
                                  className={`px-2 py-1 whitespace-nowrap border border-black`}
                                >
                                  {date.phoneNumber}
                                </td>
                              )}
                              <td className="border border-black px-2 py-1">
                                {order?.customer?.userName}
                              </td>
                              {/* <td className="border border-black px-2 py-1">
                                {order?.dispatchNote}
                              </td> */}
                              {index3 === 0 && (
                                <td
                                  rowSpan={orders}
                                  className={`px-2 py-1 whitespace-nowrap border border-black`}
                                >
                                  <Images images={date?.images} />
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                  {data?.length === 0 && (
                    <tr>
                      <td className="border border-black px-2 py-1">No Data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DispatchTableCompleted;

const Images = ({ images }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div>
      <button
        onClick={() => setOpen(true)}
        className="bg-green-500 text-white px-2 py-1"
      >
        View
      </button>
      <CustomModal
        open={open}
        setOpen={setOpen}
        className="flex justify-center items-center"
        button={<></>}
      >
        <div className="grid grid-cols-2">
          {images?.map((item) => (
            <a href={item} target="_blank" rel="noreferrer">
              <img
                src={item}
                alt="s"
                className="h-80 w-full object-contain"
                key={item}
              />
            </a>
          ))}
        </div>
      </CustomModal>
    </div>
  );
};
